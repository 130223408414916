import * as React from 'react'
import Layout from '../components/layout'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { graphql } from 'gatsby'
import CssBaseline from "@mui/material/CssBaseline";
import {Grid} from "@mui/material";
const MDXPage = ({data}) => {
  return (
    <Layout pageTitle="Page">
      <CssBaseline />
      <Grid container spacing={0}>
        <Grid item xs={1}/>
        <Grid item xs={10}>

        <MDXRenderer>
        {data.mdx.body}
      </MDXRenderer>
        </Grid>
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      body
    }
  }
`
export default MDXPage

